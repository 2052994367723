(function($, viewport){
    var visibilityDivs = {
        'xs': $('<div class="d-xs-block d-sm-none d-md-none d-lg-none d-xl-none"></div>'),
        'sm': $('<div class="d-none d-sm-block d-md-none d-lg-none d-xl-none"></div>'),
        'md': $('<div class="d-none d-md-block d-sm-none d-lg-none d-xl-none"></div>'),
        'lg': $('<div class="d-none d-lg-block d-sm-none d-md-none d-xl-none"></div>'),
        'xl': $('<div class="d-none d-xl-block d-sm-none d-md-none d-lg-none"></div>'),
    };
    viewport.use('custom', visibilityDivs);
})(jQuery, ResponsiveBootstrapToolkit);
$(document).on('ready', function () {
    // initialization of carousel
    $.HSCore.components.HSCarousel.init('[class*="js-carousel"]');

    $('#carouselCus1').slick('setOption', 'responsive', [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 4
        }
    }, {
        breakpoint: 992,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 768,
        settings: {
            slidesToShow: 2
        }
    }, {
        breakpoint: 576,
        settings: {
            slidesToShow: 1
        }
    }], true);
});

// initialization of scroll animation
$.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
// initialization of header
$.HSCore.components.HSHeader.init($('#js-header'));
$.HSCore.helpers.HSHamburgers.init('.hamburger');
// initialization of video on background
$.HSCore.helpers.HSBgVideo.init('.js-bg-video');

// initialization of HSMegaMenu component
$('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
});

// initialization of HSDropdown component
$.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
    afterOpen: function () {
        $(this).find('input[type="search"]').focus();
    }
});

// initialization of go to
$.HSCore.components.HSGoTo.init('.js-go-to');

// initialization of countdowns
var countdowns = $.HSCore.components.HSCountdown.init('.js-countdown', {
    yearsElSelector: '.js-cd-years',
    monthElSelector: '.js-cd-month',
    daysElSelector: '.js-cd-days',
    hoursElSelector: '.js-cd-hours',
    minutesElSelector: '.js-cd-minutes',
    secondsElSelector: '.js-cd-seconds'
});


// initialization of rating
$.HSCore.helpers.HSRating.init();



// initialization of form validation
$.HSCore.components.HSValidation.init('.js-validate');

// initialization of custom select
$.HSCore.components.HSSelect.init('.js-custom-select');

// initialization of quantity counter
$.HSCore.components.HSCountQty.init('.js-quantity');

// initialization of step form
$.HSCore.components.HSStepForm.init('.js-step-form');

// initialization of tabs
$.HSCore.components.HSTabs.init('[role="tablist"]');

// initialization of popups
$.HSCore.components.HSModalWindow.init('[data-modal-target]');


// initialization of counters
var counters = $.HSCore.components.HSCounter.init('[class*="js-counter"]');

$('#collapseXefi').on('show.bs.collapse', function () {
  $('#collapseXefiButton span').html('Masquer les services');
  $('#collapseXefiButton i').removeClass('hs-icon-arrow-bottom');
  $('#collapseXefiButton i').addClass('hs-icon-arrow-top');
})

$('#collapseXefi').on('hide.bs.collapse', function () {
  $('#collapseXefiButton span').html('En savoir plus');
  $('#collapseXefiButton i').removeClass('hs-icon-arrow-top');
  $('#collapseXefiButton i').addClass('hs-icon-arrow-bottom');
})

$(window).on('load', function() {
    // initialization of HSScrollBar component
    $.HSCore.components.HSScrollBar.init($('.js-scrollbar'));
});

$(window).on('resize', function () {
    setTimeout(function () {
        $.HSCore.components.HSTabs.init('[role="tablist"]');
    }, 200);
});
document.addEventListener('DOMContentLoaded', function(event) { 
    cookieChoices.showCookieConsentBar('Ce site utilise des cookies pour le confort de votre navigation. En poursuivant votre navigation, vous acceptez l\’utilisation des cookies.', 'J\’accepte', 'En savoir plus', 'http://www.ascii-info.com/mentions-legales/');
});
$('.hs-has-mega-menu > a.nav-link').on('click', function(event){
event.preventDefault();
});
function downloadJSAtOnload(script) {
    var element = document.createElement("script");
    element.src = script;
    document.body.appendChild(element);
}
function deferJsLoading(script)
{
    if (window.addEventListener)
    window.addEventListener("load", downloadJSAtOnload(script), false);
    else if (window.attachEvent)
    window.attachEvent("onload", downloadJSAtOnload(script));
    else window.onload = downloadJSAtOnload(script);
}
