/*
 2016 ThemePunch
*/
(function(){function p(a,b,c){m[m.length]=this;n||(n=a,n.event.special.typewriterDestroyed={remove:function(a){a.handler()}},q=document.createElement("textarea"));r="";this.layrs=[];a=b[0];(a=a.hasOwnProperty("opt")?a.opt:!1)&&a.hasOwnProperty("responsiveLevels")&&(a=a.responsiveLevels);Array.isArray(a)&&(this.levels=a);this.slider=b.on("typewriterDestroyed",y);c.each(this.writeLayerData.bind(this));r&&(c=document.head||document.body)&&(a=document.createElement("style"),a.type="text/css",a.innerHTML=
r,c.appendChild(a));c=w.length;for(a=0;a<c;a++)b.on("revolution.slide."+w[a],this.eventCallback.bind(this))}function x(a,b,c,f){var g=JSON.parse(a.getAttribute("data-typewriter")),k=b.replace(/(_|\|)$/,""),m=a.className,l=g.lines,q=b,p=[],t,e,u,h,d;a.className=m+" hide-typewriter-cursor";for(d in g)g.hasOwnProperty(d)&&(h=g[d],d=d.replace(/_([a-z])/g,z),this[d]="on"===h?1:"off"===h?0:!1===isNaN(h)?parseInt(h,10):h);if(this.blinking&&(r+="#"+a.id+":after {"+'content: "[char]"; animation: tp-typewriter [speed]s steps(2, start) infinite'.replace("[char]",
"one"===this.cursorType?"_":"|").replace("[speed]",.001*parseInt(this.blinkingSpeed,10))+"}#"+a.id+'.hide-typewriter-cursor:after {content: ""}',this.background))if(t=a.getAttribute("data-width").replace(/[\[\]']+/g,"").split(","),c)for(h=t.length,d=0;d<h;d++)u=c[d],u!==e&&(e=t[d],e="auto"===e||"none"===e,0<d?(e=e?"static":"absolute",r+="@media screen and (max-width: "+u+"px) {#"+a.id+".tp-caption[data-typewriter]:after {position: "+e+"}}"):e&&a.setAttribute("data-typewriter-blinking","true"),e=u);
else e=t[0],"none"!==e&&"auto"!==e||a.setAttribute("data-typewriter-blinking","true");if(this.sequenced&&l){b=[v(unescape(b))];l=l.split(",");h=l.length;for(d=0;d<h;d++)(c=n.trim(l[d]))&&(b[b.length]=v(unescape(c)));a.setAttribute("data-typewriter-sequenced","true")}else{this.sequenced=this.looped=!1;this.newlineDelay=this.linebreakDelay;l=v(b,!0).split(/\r?\n|\r/g);h=l.length;b=[];for(d=0;d<h;d++)b[b.length]=n.trim(l[d]),b[b.length]="";for(;""===b[0];)b.shift();for(;""===b[b.length-1];)b.pop()}if(this.wordDelay)if(g=
g.delays){g=g.split(",");h=g.length;for(d=l=0;d<h;d++)e=unescape(g[d]).split("|"),c=e[0],e=e[1],!1===isNaN(c)&&!1===isNaN(e)&&(c=parseInt(c),p[c+l-1]=parseInt(e),l+=c);this.spaces=p}else this.wordDelay=!1;this.el=a;this.txt=b;this.orig=q;this.clean=k;this.revapi=f;this.len=b.length;this.statc=-1!==m.search("tp-static-layer");n.data(a,"typewriter",this)}function z(a){return a[1].toUpperCase()}function v(a,b){a=a.replace(/<(?!br\s*\/?)[^>]+>/g,"").replace(/(_|\|)$/,"");a=b?a.replace(/\r?\n|\r/g," ").replace(/<br[^>]*>/gi,
"\n"):a.replace(/\r?\n|\r/g,"\u00b1").replace(/<br[^>]*>/gi,"\u00b5").replace(/\u00b1\u00b5|\u00b5\u00b1/g,"\u00b5").replace(/^\u00b1+|\u00b1+$/g,"").replace(/^\u00b5+|\u00b5+$/g,"");q.innerHTML=a;return q.value}function y(){for(var a=m.length,b;a--;)if(b=m[a],b[0]===this){for(var c=b.layrs,f=c.length;f--;)n.removeData(c[f].el,"typewriter");c=void 0;for(c in b)b.hasOwnProperty(c)&&delete b[c];m=m.splice(a,1);break}}var n,r,q,m=[],w=["onbeforeswap","layeraction","beforeredraw","afterdraw"];window.RsTypewriterAddOn=
function(a,b){if(b){var c=b.find(".tp-caption[data-typewriter]");c.length&&new p(a,b,c)}};p.prototype={eventCallback:function(a,b){this[a.namespace.replace(".slide","")](b)},onbeforeswap:function(a){var b=this.layrs,c=b.length,f;if(a=a.currentslide[0])for(;c--;)f=b[c],f.statc||(clearTimeout(f.timer),f.running=!1,f.active=!1,a.contains(f.el)||(f.el.innerHTML=f.orig,f.el.className+=" hide-typewriter-cursor"))},layeraction:function(a){if("enterstage"===a.eventtype){var b=n.data(a.layer[0],"typewriter");
b&&"html"===a.layertype&&(b.active=!0,b.start())}},beforeredraw:function(a){a=this.layrs;for(var b=a.length;b--;)a[b].onRedraw()},afterdraw:function(){for(var a=this.layrs,b=a.length;b--;)a[b].onRedraw(!0)},writeLayerData:function(a,b){var c=n.trim(b.innerHTML);c?(b.getAttribute("data-whiteboard")&&("undefined"!==typeof console&&console.log("Whiteboard is disabled for Layers with Typewriter Add-On effect enabled"),b.removeAttribute("data-whiteboard")),this.layrs[this.layrs.length]=new x(b,c,this.levels,
this.slider)):b.removeAttribute("data-typewriter")}};x.prototype={start:function(a,b){clearTimeout(this.timer);this.paused||(this.words=this.step=this.line=0,this.rpaused=this.paused=this.breaker=this.rstart=this.rewind=this.skip=!1,this.str=this.txt[this.line],this.len=this.str.length,b?(this.timer=setTimeout(this.onStart.bind(this),a),this.revapi.trigger("revolution.slide.typewriterlooped",[this.el])):(this.el.innerHTML="&nbsp;",this.el.className=this.el.className.replace(/ hide-typewriter-cursor/g,
""),this.timer=setTimeout(this.onStart.bind(this),this.startDelay)),this.running=!0,this.revapi.trigger("revolution.slide.typewriterstarted",[this.el]),this.revapi.trigger("revolution.slide.typewriternewline",[this.el,this.line+1,this.txt[this.line]]))},onStart:function(){this.el.innerHTML="";this.animate()},animate:function(){var a,b;b=this.str;var c=this.txt,f=this.step,g=this.rewind,k=g?this.deletionSpeed:this.speed;if(f<this.len)g?(this.str=b=b.slice(0,-1),this.el.innerHTML=b.replace(/\u00b5/g,
"<br>")||"&nbsp"):(this.breaker?(b="<br>",k=this.skip?this.linebreakDelay:this.newlineDelay,this.skip=this.breaker=!1,this.words=0,this.step--):(b=b[f],"\u00b5"===b&&(this.breaker=this.skip=!0,k=this.linebreakDelay)),this.breaker||(a=this.el.innerHTML,"&nbsp;"===a&&(a=""),this.el.innerHTML=a+b,this.wordDelay&&" "===b&&(b=this.words,(a=this.spaces[b])?(k=a,this.words=b<this.spaces.length-1?b+1:0):this.words+=1))),this.step++,a=!0;else{if(this.rstart){this.paused?(this.rpaused=!0,this.lastSpeed=this.newlineDelay):
this.start(this.newlineDelay,!0);return}this.line<c.length&&(this.line++,this.step=0,this.skip=!1,this.str=b=c[this.line],""===b&&(this.line++,this.breaker=!0,k=this.newlineDelay),this.line<c.length?(this.sequenced?((g=!g)?(this.line--,this.str=c[this.line],k=this.deletionDelay):(this.str=c[this.line],this.len=this.str.length,k=this.newlineDelay),this.rewind=g):(this.str=c[this.line],this.len=this.str.length),a=!0,this.words=0,this.rewind||this.revapi.trigger("revolution.slide.typewriternewline",
[this.el,this.line+1,c[this.line]])):this.looped&&(this.rstart=this.rewind=!0,this.words=0,this.line--,this.str=c[this.line],a=!0,k=this.deletionDelay))}a?this.paused?this.lastSpeed=k:k?this.timer=setTimeout(this.animate.bind(this),k):this.animate():(this.running=!1,this.hideCursor&&(this.el.className+=" hide-typewriter-cursor"),this.revapi.trigger("revolution.slide.typewriterended",[this.el]))},onRedraw:function(a){a?this.el.innerHTML=this.state:(a=window.getComputedStyle(this.el,":after").getPropertyValue("position"),
this.state=this.el.innerHTML,this.el.innerHTML="absolute"===a?this.orig:this.clean)},pause:function(){this.active&&(clearTimeout(this.timer),this.paused=!0)},resume:function(){this.active&&(this.running?this.paused&&(this.rpaused?this.start(this.lastSpeed,!0):(this.paused=!1,this.lastSpeed?this.timer=setTimeout(this.animate.bind(this),this.lastSpeed):this.animate())):this.restart())},restart:function(){this.active&&(clearTimeout(this.timer),this.paused=this.rpaused=!1,this.start())},restore:function(a){this.active&&
(clearTimeout(this.timer),this.el.innerHTML=this.clean,a&&(this.el.className+=" hide-typewriter-cursor"))}}})();